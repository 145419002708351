import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Blogs from '../components/Blogs/Blogs'
import { REAL_MONEY_GAMES_BLOGS_TAG_IDENTIFIER } from '../components/Blogs/constants'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'
import { REAL_MONEY_GAMES_BLOGS } from '../components/internal-links'
import { LATEST_RC as LEVEL2_DOWNLOAD_LINK } from '../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Real Money Gaming',
    url: REAL_MONEY_GAMES_BLOGS,
  },
]

const LearnRealMoneyGames: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={LEVEL2_DOWNLOAD_LINK}
    >
      <SEO
        title="Real Money Gaming: Learn how to deposit and withdraw | Mega"
        description="Real money gaming involves complexities of depositing and withdrawing money. Get to know the easiest method to avoid such complexities. Read now."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="realMoneyGames"
        excludeRummyPoker
        tags={[REAL_MONEY_GAMES_BLOGS_TAG_IDENTIFIER]}
        prefix={`/${process.env.GATSBY_CMS_REAL_MONEY_GAMES_PREFIX}` || '/'}
        title={
          <>
            Real Money Gaming - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnRealMoneyGames
